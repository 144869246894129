import clsx from 'clsx';
import { mls } from 'lib/multilanguagesupport';
import CheckBox from 'lib/reusable-components/Components/CheckBox/CheckBox';
import styles from './columnSettings.module.scss';
import React from 'react';
const typeOption = {
  text: {
    key: 'text',
    name: 'Text',
  },
  number: {
    key: 'number',
    name: 'Number',
  },
  decimal: {
    key: 'decimal',
    name: 'Decimal',
  },
};
interface selectDataTypeCaseProps {
  columnData: any;
  updateColumnData: Function;
}
const SelectDataTypeCase = ({ columnData, updateColumnData }: selectDataTypeCaseProps) => {
  const selectMenu = columnData?.options?.selectMenu ?? [];
  const type = columnData?.options?.type ?? typeOption.text.key;
  const addOption = ({ option = { label: '', value: '' } }) => {
    let updatedSelectMenu = [...(selectMenu ?? []), option];
    updateColumnData({
      key: 'options',
      value: { ...(columnData?.options ?? {}), selectMenu: updatedSelectMenu },
    });
  };

  const updateDataByIndex = ({
    index,
    arrayData,
    newData,
  }: {
    index: number;
    arrayData: any[];
    newData: any;
  }) => {
    const copyArrayData = Array.from(arrayData);
    copyArrayData.splice(index, 1);
    copyArrayData.splice(index, 0, newData);
    return copyArrayData;
  };
  const handleChangeOption = (e: any, optionIndex: number) => {
    const newData = {
      ...(selectMenu?.[optionIndex] ?? {}),
      [e.target.name]: e.target.value,
    };
    const updatedSelectMenu = updateDataByIndex({
      index: optionIndex,
      arrayData: selectMenu ?? [],
      newData: newData,
    });
    updateColumnData({
      key: 'options',
      value: { ...(columnData?.options ?? {}), selectMenu: updatedSelectMenu },
    });
  };
  const deleteOptionValidation = (indx: number) => {
    const updatedSelectMenu = (selectMenu ?? []).filter((_: any, index: number) => index !== indx);
    updateColumnData({
      key: 'options',
      value: { ...(columnData?.options ?? {}), selectMenu: updatedSelectMenu },
    });
  };
  const updateIsMultiple = (action: boolean) => {
    updateColumnData({ key: 'isMultiple', value: action });
  };

  const handleColTypeChange = (e: any) => {
    updateColumnData({
      key: 'options',
      value: { ...(columnData?.options ?? {}), type: e.target.value, selectMenu: [] },
    });
  };

  return (
    <>
      <div className={styles.userOuterContainer}>
        <div className='fs-6 fw-bolder text-dark'>{mls('Options')}</div>
        <div className='row align-items-center'>
          <div className='col-1'>
            <div className='fs-6 fw-bolder text-dark'>{mls('Type')}:</div>
          </div>
          <div className='col-4'>
            <select
              name='dataType'
              onChange={handleColTypeChange}
              className='form-select form-control form-control-lg form-control-solid'
              value={type}
            >
              {React.Children.toArray(
                Object.keys(typeOption).map((typekey) => {
                  const dataTypeElem = typeOption[typekey as keyof typeof typeOption];
                  return <option value={dataTypeElem.key}>{mls(dataTypeElem.name)}</option>;
                })
              )}
            </select>
          </div>
        </div>
        <div className='fs-6 fw-bolder text-dark'>{mls('Select Menu')}</div>
        {(selectMenu ?? [])?.length === 0 ? (
          <div className='text-muted  mx-6 '>{mls('No options available.')}</div>
        ) : (
          <div className='row'>
            <div className='col-6 ' style={{ marginRight: -10, marginLeft: 0 }}>
              <label className='form-label mx-6 fs-6 fw-bolder text-dark'>{mls('Label')}</label>
            </div>
            <div className='col-6 '>
              <label className='form-label mx-4 fs-6 fw-bolder text-dark'>{mls('Value')}</label>
            </div>
          </div>
        )}

        {(selectMenu ?? [])?.map((option: any, key: number) => (
          <div className='d-flex justify-content-between mt-1' key={key}>
            <div className='col-6' style={{ marginRight: 20, marginLeft: -10 }}>
              <div className='d-flex align-items-center'>
                <i
                  onClick={() => deleteOptionValidation(key)}
                  className='fas fa-trash-alt text-danger'
                  style={{ marginRight: 15, fontSize: 15, cursor: 'pointer' }}
                ></i>
                <input
                  onChange={(e) => handleChangeOption(e, key)}
                  placeholder='Label'
                  className={clsx('form-control form-control-lg')}
                  type='text'
                  name='label'
                  autoComplete='off'
                  defaultValue={option.label}
                />
              </div>
            </div>
            <div className='col-6'>
              <input
                onChange={(e) => handleChangeOption(e, key)}
                placeholder='Value'
                className={clsx('form-control form-control-lg ')}
                type={type === typeOption.text.key ? 'text' : 'number'}
                name='value'
                autoComplete='off'
                defaultValue={option.value}
              />
            </div>
          </div>
        ))}
        <button
          className='w-25 mx-2 btn btn-sm btn-primary btn-light-primary mt-3'
          onClick={() => addOption({})}
          type='button'
        >
          {mls('Add Option')}
        </button>
        <div className={styles.checkBoxOuterContainer}>
          {/* <div className='mx-6 '> */}
          <div className={styles.checkBoxInnerContainer}>
            <label className='fs-6 fw-bolder text-dark '>{mls('Is Multiple')}:</label>
            <CheckBox checked={columnData?.isMultiple ?? false} onClick={updateIsMultiple} />
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};
export default SelectDataTypeCase;

import React, { useMemo, useState } from 'react';
import { mls } from 'lib/multilanguagesupport';
import style from './roleAccess.module.scss';
import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import RoleAccessAddTeamsPopup from '../../RoleAccessAddTeamsPopup/RoleAccessAddTeamsPopup';
import RoleAccessTeamCrudContainer from './RoleAccessTeamCrudContainer';
import ColumnWiseRolesContainer from './ColumnWiseRolesContainer';
import RoleComponentFilter from '../../RoleComponentFilter/RoleComponentFilter';
import { filterTypeObject } from '../../data/appComponentData';
import { getAppComponentColumnSchema } from '../PDFTemplates/helperFuntion';

interface roleAccessProps {
  connectedTablesData: any;
  componentSchema: any;
  appSchema: any;
  setComponentSchema: Function;
}
const RoleAccess = ({
  connectedTablesData,
  componentSchema,
  setComponentSchema,
  appSchema,
}: roleAccessProps) => {
  const newConnectedTable = useMemo(() => {
    let updatedConnectedTable = {};
    Object.keys(connectedTablesData ?? {}).forEach((conectedTableKey: string) => {
      const conectedTableElem = connectedTablesData[conectedTableKey];
      const updatedConnectedTableElem = {
        tableID: conectedTableElem?.tableID,
        name: `${conectedTableElem?.name} ( ${conectedTableElem?.relationType} )`,
        relationType: conectedTableElem?.relationType,
      };
      updatedConnectedTable = {
        ...updatedConnectedTable,
        [conectedTableKey]: updatedConnectedTableElem,
      };
    });

    return updatedConnectedTable;
  }, [connectedTablesData]);
  const [selectedTable, setSelectedTable] = useState(
    Object.keys(newConnectedTable ?? {})?.[0] ?? ''
  );
  const selectedTableData = connectedTablesData?.[selectedTable] ?? {};

  const roleAccessSchema = componentSchema?.roleAccess?.[selectedTable] ?? {};

  const setRoleAccessSchema = (roleAccessData: any) => {
    setComponentSchema({
      ...componentSchema,
      roleAccess: { ...(componentSchema?.roleAccess ?? {}), [selectedTable]: roleAccessData },
    });
  };

  const appTeams: { [key: string]: any } = appSchema?.appErpTeams ?? {};

  const [isDisplayToPopup, setIsDisplayToPopup] = useState(false);
  const [selectedTeamForColumn, setSelectedTeamForColumn] = useState('');
  const [selectedTeams, setSelectedTeams] = useState(Object.keys(roleAccessSchema ?? {}));
  const handleDisplayToPopup = () => {
    setIsDisplayToPopup(!isDisplayToPopup);
  };
  const handleSelectedTeamForColumn = (selectedTeamForColumnKey: string) => {
    setSelectedTeamForColumn(selectedTeamForColumnKey);
  };
  const selectedTeamsObject = useMemo(() => {
    const appTeams: { [key: string]: any } = appSchema?.appErpTeams ?? {};

    let selectedTeamElemObject: { [key: string]: any } = {};
    (selectedTeams ?? []).forEach((selectedTeamsKey: string) => {
      const selectedTeamElem = appTeams[selectedTeamsKey];
      if (selectedTeamElem) {
        selectedTeamElemObject = {
          ...selectedTeamElemObject,
          [selectedTeamsKey]: selectedTeamElem,
        };
      }
    });
    if (!selectedTeamElemObject[selectedTeamForColumn]) {
      handleSelectedTeamForColumn(Object.keys(selectedTeamElemObject ?? {})?.[0] ?? '');
    }
    return selectedTeamElemObject;
  }, [selectedTeams, appSchema?.appErpTeams, selectedTeamForColumn]);
  const [filterData, setFilterData] = useState({
    popupStatus: false,
    filters: {},
    selectedTeamsKey: '',
    key: '',
    filterType: '',
    uniqueColumnName: '',
    specialCase: false,
  });

  const handleFilterPopupStatus = () => {
    const teamKey = filterData?.selectedTeamsKey;
    const filters = filterData?.filters;
    const key = filterData?.key;
    const filterType = filterData?.filterType;
    const uniqueColumnName = filterData?.uniqueColumnName;
    if (filterType === filterTypeObject.teamFilter.key) {
      let finalTeamAccess: { [key: string]: any } = { ...roleAccessSchema?.[teamKey] };
      finalTeamAccess = {
        ...finalTeamAccess,
        [key]: { ...(finalTeamAccess?.[key] ?? {}), componentFilter: filters ?? {} },
      };
      setRoleAccessSchema({ ...roleAccessSchema, [teamKey]: finalTeamAccess });
    }
    if (filterType === filterTypeObject.columnFilter.key) {
      let finalTeamAccess: { [key: string]: any } = { ...roleAccessSchema?.[teamKey] };
      finalTeamAccess = {
        ...finalTeamAccess,
        [key]: {
          ...(finalTeamAccess?.[key] ?? {}),
          columnFilter: {
            ...(finalTeamAccess?.[key]?.columnFilter ?? {}),
            [uniqueColumnName]: filters ?? {},
          },
        },
      };

      setRoleAccessSchema({ ...roleAccessSchema, [teamKey]: finalTeamAccess });
    }
    setFilterData({ ...filterData, popupStatus: !filterData.popupStatus, specialCase: false });
  };
  const handleFilters = (action: any) => {
    setFilterData({ ...filterData, filters: action });
  };

  const columnSchema = useMemo(() => {
    return getAppComponentColumnSchema({
      connectedTableList: componentSchema?.connectedTableList ?? [],
      connectedTablesData: connectedTablesData,
    });
  }, [componentSchema?.connectedTableList, connectedTablesData]);

  const handleTableSelection = (action: string) => {
    setSelectedTable(action);
  };
  return (
    <div className={style.roleAccessContainer}>
      <div>
        {isDisplayToPopup ? (
          <RoleAccessAddTeamsPopup
            isDisplayToPopup={isDisplayToPopup}
            setIsDisplayToPopup={setIsDisplayToPopup}
            selectedTeams={selectedTeams}
            setSelectedTeams={setSelectedTeams}
            roleAccessSchema={roleAccessSchema}
            setRoleAccessSchema={setRoleAccessSchema}
            selectedTableData={selectedTableData}
            newConnectedTable={newConnectedTable}
            componentSchema={componentSchema}
            setComponentSchema={setComponentSchema}
            connectedTablesData={connectedTablesData}
          />
        ) : null}
        {filterData.popupStatus ? (
          <RoleComponentFilter
            setChartFilter={handleFilters}
            chartFilter={filterData?.filters ?? {}}
            allTableData={columnSchema}
            appID={''}
            sheetID={''}
            isJoinedTable={false}
            specialCase={filterData.specialCase}
            isPopUp={filterData.popupStatus}
            setIsPopUp={handleFilterPopupStatus}
          />
        ) : null}
      </div>
      <div className={style.columnWiseRoles}>
        <div className={style.columnWiseRolesTitle}>{mls('Select Table')}</div>
        <div className={style.selectedRolesDropDownContainer}>
          <ObjectDropDown
            object={newConnectedTable}
            dropDownValue={selectedTable}
            mainKey='tableID'
            setdropDownValue={handleTableSelection}
            ObjectInObject
            inputPlaceHolder={mls('Select Table')}
          />
        </div>
      </div>

      <div className={style.roleAccessInnerContainer}>
        <div className={style.roleAccessTeamSelection}>
          {mls('Allow Teams to give Access')}
          <button
            className='primaryButton secondaryButtonColor'
            onClick={() => handleDisplayToPopup()}
          >
            {mls('Select Teams')}
          </button>
        </div>
        <RoleAccessTeamCrudContainer
          appTeams={appTeams}
          roleAccessSchema={roleAccessSchema}
          setRoleAccessSchema={setRoleAccessSchema}
          setFilterData={setFilterData}
          filterData={filterData}
          selectedTableData={selectedTableData}
        />
        <div className={style.columnWiseRoles}>
          <div className={style.columnWiseRolesTitle}>{mls('Column wise roles for each team')}</div>
          <div className={style.selectedRolesDropDownContainer}>
            <ObjectDropDown
              object={selectedTeamsObject}
              dropDownValue={selectedTeamForColumn}
              mainKey='teamID'
              setdropDownValue={handleSelectedTeamForColumn}
              ObjectInObject
              inputPlaceHolder={mls('Select Teams')}
            />
          </div>
        </div>
        <ColumnWiseRolesContainer
          connectedTablesData={connectedTablesData}
          selectedTeamForColumn={selectedTeamForColumn}
          roleAccessSchema={roleAccessSchema}
          setRoleAccessSchema={setRoleAccessSchema}
          setFilterData={setFilterData}
          filterData={filterData}
          selectedTableData={selectedTableData}
        />
      </div>
    </div>
  );
};

export default RoleAccess;

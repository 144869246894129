import React, { useState, useRef } from 'react';
import Editor from '@monaco-editor/react';
import { Play } from 'react-bootstrap-icons';
import styles from './codeCustomization.module.scss';
import { mls } from 'lib/multilanguagesupport';
import { v4 as uuid } from 'uuid';
import { CircularProgress } from '@mui/material';
import { _supistaApiPost } from 'lib/server-connection/connections';
import useDivDimensions from 'lib/reusable-components/hooks/divDimensionHelper';
import {
  buttonTypeObject,
  codeContainerTypes,
  defaultCode,
  outputTypeObject,
} from '../../data/appComponentData';
import { getDefaultCode } from '../../utils/componentSettingFunction';
import { toast } from 'react-toastify';
import { checkCustomizeERPPresence } from '../../utils/customizeErpCheckFunctions';
import Modal from '@mui/material/Modal';
interface queryContainerProps {
  componentSchema: any;
  activeButtonKey: string;
  title: string;
  selectedCode: { [key: string]: any };
  selectedTable: string;
  udpateCodeCustomization: Function;
  buttonType: string;
  codeContainerType: string;
  setCodeContainerType: Function;
  outputData: any;
  code: any;
  handleCodeChange: any;
  inputData: any;
  setInputData: Function;
  setOutputData: Function;
  setIsInputChanged: Function;
  appID: any;
  sampleData: any;
  setCode: Function;
}
const QueryContainer = ({
  componentSchema,
  activeButtonKey,
  title,
  selectedCode,
  selectedTable,
  udpateCodeCustomization,
  buttonType,
  codeContainerType,
  setCodeContainerType,
  outputData,
  setOutputData,
  code,
  handleCodeChange,
  inputData,
  setInputData,
  appID,
  sampleData,
  setIsInputChanged,
  setCode,
}: queryContainerProps) => {
  const codeContainerRef = useRef(null);
  const { height: codeContainerHeight } = useDivDimensions(codeContainerRef);
  const monacoRef = useRef<any>(null);
  const [isCodeTestLoading, setIsCodeTestLoading] = useState(false);

  const handleEditorDidMount = (editor: any) => {
    monacoRef.current = editor;

    editor.updateOptions({ readOnly: false });
    editor.setValue(JSON.stringify(sampleData));
    editor.getAction('editor.action.formatDocument').run();

    setTimeout(() => {
      editor.updateOptions({ readOnly: true });
    }, 3000);
  };
  const handleTestCode = async () => {
    const componentID = componentSchema?.componentID;
    const baseUrl = `app/${appID}/TestCustomCode/${componentID}`;
    const selectedData = getDefaultCode({
      componentSchema,
      selectedTable,
      actionButtonKey: activeButtonKey,
      buttonType,
    });

    const functionID = selectedData?.functionID ?? uuid();
    let parsedInput = {};
    try {
      parsedInput = JSON.parse(inputData ?? '');
    } catch (error) {
      toast.error(`Error in Input:
        ${error}`);
      return;
    }

    const isERPPresent = checkCustomizeERPPresence({
      inputString: code,
      functionType: buttonType,
    });
    if (!isERPPresent) {
      // toast.error(`function customizeERP and there parameters shouldn't be changed!`);
      toast.error(`The customizeERP function and its parameters should not be modified!`);
      return;
    }

    const data = {
      functionCode: code,
      functionID: functionID,
      functionName: 'customizeERP',
      inputs: parsedInput ?? {},
      isFormCustomization: activeButtonKey === buttonTypeObject.formValidation.key ? true : false,
    };
    setIsCodeTestLoading(true);
    const componentRes = await _supistaApiPost(baseUrl, { data });
    if (componentRes?.__d3__success) {
      if (buttonType === buttonTypeObject.CRUD.key) {
        const updatedCrudFunction = {
          fuctionName: 'displayFunc',
          functionID: functionID,
          functionCode: code,
          params: [],
          type: 'CUSTOM',
        };
        // const updatedComonentSchema = {
        //   ...(componentSchema ?? {}),
        //   crudOp: { ...(componentSchema?.crudOp ?? {}), [activeButtonKey]: updatedCrudFunction },
        // };
        // setComponentSchema(updatedComonentSchema);
        udpateCodeCustomization({
          selectedTable,
          codeCustomizationData: {
            ...selectedCode,
            crudOp: { ...(selectedCode?.crudOp ?? {}), [activeButtonKey]: updatedCrudFunction },
          },
        });
      } else if (buttonType === buttonTypeObject.formValidation.key) {
        const updatedCrudFunction = {
          fuctionName: 'displayFunc',
          functionID: functionID,
          functionCode: code,
          params: [],
          type: 'CUSTOM',
        };
        udpateCodeCustomization({
          selectedTable,
          codeCustomizationData: {
            ...selectedCode,
            formValidation: updatedCrudFunction,
          },
        });
      } else if (buttonType === buttonTypeObject.actionButton.key) {
        const updatedCrudFunction = {
          fuctionName: 'actionFunc',
          functionID: functionID,
          functionCode: code,
          displayWhen: componentSchema?.actionButton?.[activeButtonKey]?.displayWhen ?? [],
          displayTo: componentSchema?.actionButton?.[activeButtonKey]?.displayTo ?? {},
          title: title,
        };
        udpateCodeCustomization({
          selectedTable,
          codeCustomizationData: {
            ...selectedCode,
            crudOp: {
              ...(selectedCode?.actionButton ?? {}),
              [activeButtonKey]: updatedCrudFunction,
            },
          },
        });
      }
      let finalOutput = { ...outputData };
      if (componentRes?.response?.codeResponse?.errorType) {
        finalOutput = {
          ...finalOutput,
          msg: { response: componentRes?.response?.codeResponse ?? {} },
          outputType: outputTypeObject.error,
        };
      } else {
        finalOutput = {
          ...finalOutput,
          msg: { response: componentRes?.response?.codeResponse ?? {} },
          outputType: outputTypeObject.success,
        };
      }
      setOutputData(finalOutput);
      setCodeContainerType(codeContainerTypes.output.key);
      setIsCodeTestLoading(false);
    } else {
      setIsCodeTestLoading(false);
    }
  };
  const [confirmResetModal, setConfirmResetModal] = useState(false);
  const handleResetCode = () => {
    const selectedData = getDefaultCode({
      componentSchema,
      selectedTable,
      actionButtonKey: activeButtonKey,
      buttonType,
    });
    setCode(selectedData?.functionCode ?? defaultCode);
    setConfirmResetModal(false);
  };
  return (
    <div
      className={`${styles.queryContainer} ${
        buttonType === buttonTypeObject.actionButton.key ? styles.queryContainerWithPermission : ''
      }`}
    >
      <div className={styles.queryEditorTabOuterContainer}>
        <div className={styles.queryEditorTabHeaderContainer}>
          <div className={styles.queryEditorTabContainer}>
            {React.Children.toArray(
              Object.keys(codeContainerTypes).map((codeContainerTypeKey: string) => {
                const codeContainerTypeElem: any =
                  codeContainerTypes[codeContainerTypeKey as keyof typeof codeContainerTypes];
                return (
                  <div
                    className={`${styles.queryEditorTabOption} ${
                      codeContainerType === codeContainerTypeKey ? styles.activeContainerTab : ''
                    } ${
                      codeContainerTypeElem?.isLowerResolutionTab ? styles.lowerResolutionTab : ''
                    }`}
                    onClick={() => setCodeContainerType(codeContainerTypeElem.key)}
                  >
                    {mls(codeContainerTypeElem.name)}
                    {codeContainerTypeElem.key === codeContainerTypes.output.key ? (
                      <div
                        className={`${styles.outputColorIndicator} ${
                          styles?.[outputData?.outputType?.class ?? outputTypeObject?.normal?.class]
                        }`}
                      ></div>
                    ) : null}
                  </div>
                );
              })
            )}
          </div>
          <ConfirmReset
            confirmResetModal={confirmResetModal}
            setConfirmResetModal={setConfirmResetModal}
            handleReset={handleResetCode}
          />

          <div className={` ${styles.resetCodeButton}`} onClick={() => setConfirmResetModal(true)}>
            {mls('Reset Code')}
          </div>
          <div
            // className={`primaryButton secondaryButtonColor ${styles.runCodeButton}`}
            className={` ${styles.runCodeButton}`}
            onClick={() => handleTestCode()}
          >
            {isCodeTestLoading ? (
              <>
                <CircularProgress color='inherit' size={10} /> &nbsp;
              </>
            ) : null}
            {mls('Run Code')}
            &nbsp;
            <Play />
          </div>
        </div>
        <div className={styles.queryEditorInnerContainer}>
          <div className={styles.queryEditorContainer}>
            <div className={styles.querytitleArea}>
              {codeContainerType === codeContainerTypes.code.key ? mls('Commands') : null}
              {codeContainerType === codeContainerTypes.output.key ? mls('Output') : null}
              {codeContainerType === codeContainerTypes.input.key ? mls('Input') : null}
            </div>

            <div className={styles.queryEditorArea} ref={codeContainerRef}>
              <div
                style={
                  codeContainerType === codeContainerTypes.code.key
                    ? { height: '100%' }
                    : { display: 'none', height: '100%' }
                }
              >
                <Editor
                  height={codeContainerHeight}
                  defaultLanguage='javascript'
                  value={code}
                  onChange={handleCodeChange}
                  theme='vs-dark'
                />
              </div>
              <div
                style={
                  codeContainerType === codeContainerTypes.output.key
                    ? { height: '100%' }
                    : { display: 'none', height: '100%' }
                }
              >
                <Editor
                  height={codeContainerHeight}
                  defaultLanguage='json'
                  value={JSON.stringify(outputData?.msg ?? '')}
                  onChange={() => {}}
                  options={{
                    automaticLayout: true,
                    formatOnPaste: true,
                    formatOnType: true,
                    // readOnly: true,
                  }}
                  onMount={handleEditorDidMount}
                  theme='vs-dark'
                />
              </div>
              <div
                style={
                  codeContainerType === codeContainerTypes.input.key
                    ? { height: '100%' }
                    : { display: 'none', height: '100%' }
                }
              >
                <Editor
                  height={codeContainerHeight}
                  defaultLanguage='json'
                  value={inputData}
                  onChange={(e) => {
                    setInputData(e);
                    setIsInputChanged(true);
                  }}
                  theme='vs-dark'
                  // options={{ lineNumbers: false }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.queryOutputContainer}>
        <div className={styles.querytitleArea}>{mls('Input')}</div>
        <div className={styles.queryEditorArea}>
          <Editor
            height='100%'
            // height='10rem'
            defaultLanguage='json'
            value={inputData}
            onChange={(e) => {
              setInputData(e);
              setIsInputChanged(true);
            }}
            theme='vs-dark'
            // options={{ lineNumbers: false }}
          />
        </div>
      </div>
    </div>
  );
};

export default QueryContainer;
const ConfirmReset = ({
  confirmResetModal,
  setConfirmResetModal,
  handleReset,
}: {
  confirmResetModal: boolean;
  setConfirmResetModal: Function;
  handleReset: Function;
}) => {
  return (
    <Modal open={confirmResetModal} onClose={() => setConfirmResetModal(false)}>
      <div className='modal-dialog modal-dialog-centered mw-600px'>
        <div className='modal-content d-flex justify-content-center align-items-center p-5'>
          <p className='p-5 mb-7 fs-4 fw-bold'>
            {mls('Are you sure you want to Reset this Code')}?
          </p>
          <div className='d-flex'>
            <button
              data-bs-dismiss='modal'
              onClick={() => handleReset()}
              style={{ marginTop: -15 }}
              className='btn btn-sm mb-2 btn-danger btn-light-primary mx-4'
            >
              {mls('Reset Code')}
            </button>
            <button
              onClick={() => setConfirmResetModal(false)}
              style={{ marginTop: -15 }}
              className='btn btn-sm mb-2 btn-primary btn-light-primary mx-4'
            >
              {mls('Cancel')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

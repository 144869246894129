import React, { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import { mls } from 'lib/multilanguagesupport';
import styles from './columnSettings.module.scss';
import { erpAllDataType, erpValidationTypes } from '../../data/erpData';

const ValidationSection = ({
  columnSchema,
  handleUpdateColumnData,
  handleTableUpdateColumnName,
}: {
  columnSchema: { [key: string]: any };
  handleUpdateColumnData: Function;
  handleTableUpdateColumnName: Function;
}) => {
  const sectionRef = useRef(null);
  const [localValidationData, setLocalValidationData] = useState<any>(
    columnSchema?.validation ?? []
  );
  const validationOptions = erpAllDataType[columnSchema?.dataType as keyof typeof erpAllDataType]
    ?.validation ?? { isRequired: erpValidationTypes.isRequired };

  const [validationError, setValidationError] = useState('');
  const handleChangeValidation = (e: any, valIndex: number, field: any) => {
    e.preventDefault();
    const value = e.target.value;
    setLocalValidationData((prevLocalValidationData: any) => {
      const updatedValidation: any[] = Array.from(prevLocalValidationData ?? []);
      if (!updatedValidation[valIndex]) {
        updatedValidation[valIndex] = {};
      }

      updatedValidation[valIndex] = {
        ...updatedValidation[valIndex],
        [field]: value,
      };

      if (field === 'type') {
        if (value === erpValidationTypes.isLength.key) {
          updatedValidation[valIndex] = {
            ...updatedValidation[valIndex],
            minValue: 0,
            maxValue: 1,
          };
        } else {
          let copyValidationElem = { ...(updatedValidation[valIndex] ?? {}) };
          delete copyValidationElem.minValue;
          delete copyValidationElem.maxValue;
          updatedValidation[valIndex] = copyValidationElem;
        }
      }

      // Check for errors
      const currentValidation = updatedValidation[valIndex];
      let error = '';

      if (field === 'type' && !value) {
        error = 'Validation type is required';
      } else if (field === 'message' && !value.trim()) {
        error = 'Error message is required';
      }

      if (error) {
        currentValidation.error = error;
      } else {
        delete currentValidation.error;
      }
      return updatedValidation;
    });
  };

  const addValidation = (e: any) => {
    e.preventDefault();
    setLocalValidationData((prevLocalValidationData: any) => {
      const updatedValidation = Array.from(prevLocalValidationData ?? []);
      const hasIsRequired = updatedValidation.some(
        (v: any) => v.type === erpValidationTypes.isRequired.key
      );
      let newType = '';
      if (!hasIsRequired) {
        newType = erpValidationTypes.isRequired.key;
      } else {
        const tempTypes = [
          erpValidationTypes.isEmail.key,
          erpValidationTypes.isNumeric.key,
          erpValidationTypes.isLength.key,
          erpValidationTypes.isBool.key,
          erpValidationTypes.isDate.key,
          erpValidationTypes.isAlphabet.key,
          erpValidationTypes.isMobile.key,
        ];
        newType =
          tempTypes.find((type) => !updatedValidation.some((v: any) => v.type === type)) || '';
      }

      const newValidation = { type: newType, message: '' };

      if (newType) {
        updatedValidation.push(newValidation);
        // console.log('Updated validation array:', updatedValidation);
      } else {
        // console.log('No unique validation types available to add');
      }

      return updatedValidation;
    });
  };

  const deleteValidation = (valIndex: number) => {
    setValidationError('');
    setLocalValidationData((prevLocalValidationData: any) => {
      const updatedValidation = (prevLocalValidationData ?? []).filter(
        (_: any, index: number) => index !== valIndex
      );
      return updatedValidation;
    });
  };

  const handleSectionBlur = () => {
    const { hasErrors, errorMessage } = checkForErrors(localValidationData);
    if (hasErrors) {
      setValidationError(errorMessage);
    } else {
      setValidationError('');
      handleTableUpdateColumnName({
        columnData: { ...columnSchema, validation: localValidationData },
        columnName: columnSchema?.columnName,
      });
      handleUpdateColumnData({ ...columnSchema, validation: localValidationData });
    }
  };

  const checkForErrors = (schema: any) => {
    const validations = schema || [];
    let hasErrors = false;
    let errorMessage = '';

    validations.forEach((validation: any) => {
      if (validation.type === 'isLength') {
        if (!validation.minValue || !validation.maxValue) {
          hasErrors = true;
          errorMessage = 'Length range must be specified for isLength validation';
        } else if (parseInt(validation.minValue) > parseInt(validation.maxValue)) {
          hasErrors = true;
          errorMessage = 'Min length cannot be greater than max length';
        }
      }

      if (validation.type === 'isRequired' && !validation.message.trim()) {
        hasErrors = true;
        errorMessage = 'Required fields must have an error message';
      }
    });

    return { hasErrors, errorMessage };
  };

  useEffect(() => {
    setLocalValidationData(columnSchema?.validation ?? []);
  }, [columnSchema]);

  return (
    <div
      className={styles.userOuterContainer}
      ref={sectionRef}
      onBlur={handleSectionBlur}
      key={columnSchema?.type ?? ''}
    >
      <p className='mb-5 fs-6 fw-bolder text-dark'>{mls('Validation')}</p>
      <div style={{ maxHeight: 300, overflowY: 'auto', overflowX: 'hidden' }}>
        {!localValidationData?.length && (
          <p className='text-muted px-4'>{mls('No validations added.')} </p>
        )}
        {localValidationData?.length !== 0 && (
          <div className='row'>
            <div className='col-6 '>
              <label className='form-label mx-9 fs-6 fw-bolder text-dark'>{mls('Type')}</label>
            </div>
            <label className='col-6 form-label fs-6 fw-bolder text-dark'>
              {mls('Error Message')}
            </label>
          </div>
        )}
        {localValidationData?.map((val: any, key: number) => (
          <div className='row mt-1' key={key}>
            <div className='col-6'>
              <div className='d-flex align-items-center'>
                <i
                  onClick={() => deleteValidation(key)}
                  className='fas fa-trash-alt text-danger'
                  style={{ marginRight: 15, fontSize: 15, cursor: 'pointer' }}
                ></i>
                <select
                  name='type'
                  onChange={(e) => handleChangeValidation(e, key, 'type')}
                  className='form-select form-control form-control-lg'
                >
                  <option value='' selected={val.type === '' ? true : false} hidden>
                    Select...
                  </option>
                  {React.Children.toArray(
                    Object.keys(validationOptions).map((validationKey: string) => {
                      const validationElem: any =
                        validationOptions[validationKey as keyof typeof validationOptions];
                      return (
                        <option
                          value={validationElem.key}
                          selected={val.type === validationElem.key ? true : false}
                        >
                          {validationElem.name}
                        </option>
                      );
                    })
                  )}
                </select>
              </div>
            </div>
            <div className='col-6'>
              <input
                placeholder='Error Message'
                className={clsx('form-control form-control-lg', { 'is-invalid': val.error })}
                type='text'
                name='error'
                autoComplete='off'
                onChange={(e) => handleChangeValidation(e, key, 'message')}
                value={val.message}
              />
            </div>
            {val.error && <div className='col-12 text-danger mt-1'>{val.error}</div>}
            {val.type === erpValidationTypes.isLength.key && (
              // <div className={`row mx-3 ${styles.valueFieldInnerContainer}`}>
              <div className={`${styles.valueFieldOuterContainer}`}>
                <div className='col-6'>
                  <label className='form-label fs-6 fw-bolder text-dark mt-3'>
                    {mls('Min Value')}
                  </label>
                  <input
                    placeholder='Min Value'
                    className={clsx('form-control form-control-lg ')}
                    type='number'
                    name='minValue'
                    autoComplete='off'
                    value={val.minValue ?? 0}
                    // style={{ marginRight: 15, fontSize: 15, cursor: 'pointer' }}
                    onChange={(e) => handleChangeValidation(e, key, 'minValue')}
                  />
                </div>
                <div className='col-6'>
                  <label className='form-label fs-6 fw-bolder text-dark mt-3'>
                    {mls('Max Value')}
                  </label>
                  <input
                    placeholder='Max Value'
                    className={clsx('form-control form-control-lg ')}
                    type='number'
                    name='maxValue'
                    autoComplete='off'
                    value={val.maxValue ?? ''}
                    onChange={(e) => handleChangeValidation(e, key, 'maxValue')}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div>
        <button
          className='btn btn-sm btn-primary btn-light-primary mx-4 mt-3'
          onClick={(e) => addValidation(e)}
        >
          {mls('Add validation')}
        </button>
      </div>
      {validationError && <div className='text-danger mt-2'>{validationError}</div>}
    </div>
  );
};

export default ValidationSection;

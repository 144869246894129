/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTSVG } from '_metronic/helpers';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import { mls } from 'lib/multilanguagesupport';
import React, { useState } from 'react';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import EditTeam from './EditTeam';
import generateUUID from 'lib/reusable-components/reusableUtils/updateSchema/generateUUID';
import { useParams } from 'react-router-dom';

const Teams = () => {
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  const appTeams = appSchema?.appErpTeams || {};

  const { appID } = useParams();

  const [isEdit, setEdit] = useState(null);

  const handleEdit = (value) => {
    setEdit(value);
  };
  return (
    <>
      {isEdit == null && (
        <div className={`card`}>
          {/* begin::Header */}
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-4 mb-1'>{mls('Manage Teams')}</span>
              <span className='text-muted mt-1 fw-bold fs-7'>
                {mls('Manage teams to create role based access for users')}
              </span>
            </h3>
            <div className='card-toolbar'>
              <button
                onClick={() =>
                  handleEdit({ teamID: generateUUID(), teamSchema: { privilege: '' }, isNew: true })
                }
                className='btn btn-sm btn-light-primary'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                {mls('New Team')}
              </button>
            </div>
          </div>
          <hr />
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-rounded table-row-bordered align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bolder text-muted bg-light'>
                    <th className='ps-4 min-w-325px rounded-start'>{mls('Team Name')}</th>
                    <th className='min-w-125px'>{mls('No. Of Users')}</th>
                    <th className='min-w-125px'>{mls('Privilige')}</th>
                    <th className='min-w-200px pe-4 text-end rounded-end'>{mls('Update')}</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody style={{ borderTop: '0px' }}>
                  {Object.keys(appTeams).map((teamID) => (
                    <tr key={teamID}>
                      <td>
                        <div className='d-flex ms-4 align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span
                              onClick={() =>
                                handleEdit({ teamID, teamSchema: appTeams[teamID], isNew: false })
                              }
                              style={{ cursor: 'pointer' }}
                              className='text-dark fw-bold text-hover-primary mb-1 fs-7'
                            >
                              {appTeams[teamID]?.name || ''}
                            </span>
                            <span className='text-muted fw-bold text-muted d-block fs-7'>
                              {appTeams[teamID]?.desc || ''}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className='text-dark fw-bold d-block mb-1 fs-7'>
                          {(appTeams[teamID]?.usersList || []).length}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark fw-bold d-block mb-1 fs-7'>
                          {teamID === 'Admin' || teamID === 'Creator'
                            ? teamID
                            : appTeams[teamID]?.privilege || 'Component Access'}
                        </span>
                      </td>
                      <td className='text-end'>
                        <button
                          onClick={() =>
                            handleEdit({ teamID, teamSchema: appTeams[teamID], isNew: false })
                          }
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>

              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      )}
      {isEdit != null && (
        <EditTeam
          isNew={isEdit['isNew']}
          teamSchema={isEdit['teamSchema']}
          teamID={isEdit['teamID']}
          setEdit={setEdit}
          appID={appID}
          analyticsSchema={appSchema}
        />
      )}
    </>
  );
};

export default Teams;

import React, { useState } from 'react';
import { Row, Col, Button, ListGroup } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';
import styles from './codeCustomization.module.scss';
import { mls } from 'lib/multilanguagesupport';
import { v4 as uuid } from 'uuid';
import QueryGenerator from '../../QueryGenerator/QueryGenerator';
import {
  buttonTypeObject,
  codeContainerTypes,
  crudTypeButtonObject,
  defaultCode,
  defaultOutputData,
  specialDataTypeName,
} from '../../data/appComponentData';
import { getDefaultCode, getDefaultInput } from '../../utils/componentSettingFunction';
import useUpdateEffect from 'lib/reusable-components/hooks/useUpdateEffect';
import AICustomizationEditorContainer from './AICustomizationEditorContainer';

const AIGeneratedCodeEditor = ({
  componentSchema,
  appID,
  newConnectedTableData,
  connectedTablesData,
  setComponentSchema,
  componentRowList,
  selectedTable,
  selectedCode,
  udpateCodeCustomization,
  selectedTableData,
}) => {
  const [buttonType, setButtonType] = useState(buttonTypeObject.CRUD.key);
  const [activeButtonKey, setActiveButtonKey] = useState(crudTypeButtonObject.GET.key);
  const [codeContainerType, setCodeContainerType] = useState(codeContainerTypes.code.key);
  const [outputData, setOutputData] = useState(defaultOutputData);
  const [selectedId, setSelectedId] = useState(componentRowList?.[0]?.id ?? '');
  const [selectedParentId, setSelectedParentId] = useState(componentRowList?.[0]?.id ?? '');
  const [inputData, setInputData] = useState(
    getDefaultInput({
      connectedTablesData: connectedTablesData,
      selectedTable,
      actionButtonKey: activeButtonKey,
      buttonType: buttonType,
      selectedId: selectedId,
      parentId: selectedParentId,
    }) ?? '{}'
  );
  const [isInputChanged, setIsInputChanged] = useState(false);

  const [code, setCode] = useState(() => {
    const selectedData = getDefaultCode({
      componentSchema,
      selectedTable,
      actionButtonKey: activeButtonKey,
      buttonType,
    });
    return selectedData?.functionCode ?? defaultCode;
  });
  useUpdateEffect(() => {
    setButtonType(buttonTypeObject.CRUD.key);
    setActiveButtonKey(crudTypeButtonObject.POST.key);
    setCodeContainerType(codeContainerTypes.code.key);
    const selectedData = getDefaultCode({
      componentSchema,
      selectedTable,
      actionButtonKey: crudTypeButtonObject.POST.key,
      buttonType: buttonTypeObject.CRUD.key,
    });
    setCode(selectedData?.functionCode ?? defaultCode);
  }, [selectedTable]);

  const [title, setTitle] = useState(() => {
    const selectedData = getDefaultCode({
      componentSchema,
      selectedTable,
      actionButtonKey: activeButtonKey,
      buttonType,
    });
    return selectedData?.title ?? 'New Action';
  });
  const [errors, setErrors] = useState({});

  const handleTypeSelect = ({ targetButtonKey, buttonType }) => {
    setButtonType(buttonType);
    setActiveButtonKey(targetButtonKey);
    setErrors({});
    const selectedData = getDefaultCode({
      componentSchema,
      selectedTable,
      actionButtonKey: targetButtonKey,
      buttonType: buttonType,
    });

    setCode(selectedData?.functionCode || '');
    setOutputData(defaultOutputData);
    const inputValue = getDefaultInput({
      connectedTablesData: connectedTablesData,
      selectedTable,
      actionButtonKey: targetButtonKey,
      buttonType: buttonType,
      selectedId: selectedId,
      parentId: selectedParentId,
    });

    setInputData(inputValue);
    setIsInputChanged(false);
    setCodeContainerType(codeContainerTypes.code.key);
    if (buttonType === buttonTypeObject.actionButton.key) {
      setTitle(selectedData?.title || '');
    }
  };
  const handleCodeChange = (value) => {
    setCode(value);
  };
  const actionButtonData = selectedCode?.actionButton ?? {};

  const handleAddActionButton = () => {
    const actionButtonID = uuid();
    const selectedData = getDefaultCode({
      componentSchema,
      selectedTable,
      actionButtonKey: actionButtonID,
      buttonType: buttonTypeObject.actionButton.key,
    });

    setButtonType(buttonTypeObject.actionButton.key);
    setActiveButtonKey(actionButtonID);
    setErrors({});
    setCode(selectedData?.functionCode || '');
    setTitle(selectedData?.title || '');
    udpateCodeCustomization({
      selectedTable,
      codeCustomizationData: {
        ...selectedCode,
        actionButton: { ...(selectedCode?.actionButton ?? {}), [actionButtonID]: selectedData },
      },
    });
  };

  return (
    <div className={styles.aiCustomizationOuter}>
      <Row className={styles.aiCustomizationInner}>
        <Col md={3} className={styles.apiTypes}>
          <ListGroup>
            {selectedTableData?.relationType === specialDataTypeName?.belongsTo?.key ? (
              <>
                <ListGroup.Item
                  key={crudTypeButtonObject.GET.key}
                  action
                  active={
                    buttonType === buttonTypeObject.CRUD.key &&
                    activeButtonKey === crudTypeButtonObject.GET.key
                  }
                  onClick={() =>
                    handleTypeSelect({
                      targetButtonKey: crudTypeButtonObject.GET.key,
                      buttonType: buttonTypeObject.CRUD.key,
                    })
                  }
                >
                  {mls(crudTypeButtonObject.GET.label)}
                </ListGroup.Item>
              </>
            ) : (
              <>
                {Object.values(crudTypeButtonObject).map((type) => (
                  <ListGroup.Item
                    key={type.key}
                    action
                    active={
                      buttonType === buttonTypeObject.CRUD.key && activeButtonKey === type.key
                    }
                    onClick={() =>
                      handleTypeSelect({
                        targetButtonKey: type.key,
                        buttonType: buttonTypeObject.CRUD.key,
                      })
                    }
                  >
                    {mls(type.label)}
                  </ListGroup.Item>
                ))}
                <ListGroup.Item
                  key={buttonTypeObject.formValidation.key}
                  action
                  active={
                    buttonType === buttonTypeObject.formValidation.key &&
                    activeButtonKey === buttonTypeObject.formValidation.key
                  }
                  onClick={() =>
                    handleTypeSelect({
                      targetButtonKey: buttonTypeObject.formValidation.key,
                      buttonType: buttonTypeObject.formValidation.key,
                    })
                  }
                >
                  {mls(buttonTypeObject.formValidation.name)}
                </ListGroup.Item>
                <ListGroup.Item className={styles.actionButtonContainer}>
                  <b>{mls('Action Buttons')}</b>
                  <hr />
                  <ListGroup>
                    {Object.keys(actionButtonData ?? {}).map((actionButtonKey, index) => {
                      const actionButtonElem = actionButtonData[actionButtonKey];
                      return (
                        <ListGroup.Item
                          key={index}
                          action
                          active={
                            buttonType === buttonTypeObject.actionButton.key &&
                            activeButtonKey === actionButtonKey
                          }
                          onClick={() =>
                            handleTypeSelect({
                              targetButtonKey: actionButtonKey,
                              buttonType: buttonTypeObject.actionButton.key,
                            })
                          }
                        >
                          {actionButtonElem?.title}
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                  <Button
                    variant='link'
                    onClick={handleAddActionButton}
                    className={styles.addButton}
                  >
                    {mls('Add Buttons')}
                    <Plus />
                  </Button>
                </ListGroup.Item>
              </>
            )}
          </ListGroup>
        </Col>
        <AICustomizationEditorContainer
          errors={errors}
          AICustomizationEditorContainer
          componentRowList={componentRowList}
          componentSchema={componentSchema}
          setTitle={setTitle}
          activeButtonKey={activeButtonKey}
          setComponentSchema={setComponentSchema}
          title={title}
          setErrors={setErrors}
          selectedCode={selectedCode}
          selectedTable={selectedTable}
          udpateCodeCustomization={udpateCodeCustomization}
          buttonType={buttonType}
          codeContainerType={codeContainerType}
          setCodeContainerType={setCodeContainerType}
          outputData={outputData}
          code={code}
          handleCodeChange={handleCodeChange}
          inputData={inputData}
          setInputData={setInputData}
          setIsInputChanged={setIsInputChanged}
          appID={appID}
          setOutputData={setOutputData}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          selectedParentId={selectedParentId}
          setSelectedParentId={setSelectedParentId}
          selectedTableData={selectedTableData}
          connectedTablesData={connectedTablesData}
          isInputChanged={isInputChanged}
          setCode={setCode}
        />
      </Row>
      <QueryGenerator
        newConnectedTableData={newConnectedTableData}
        appID={appID}
        handleCodeChange={handleCodeChange}
        code={code}
        buttonType={buttonType}
        activeButtonKey={activeButtonKey}
      />
    </div>
  );
};

export default AIGeneratedCodeEditor;

import React from 'react';

import SlideSwitch from 'lib/reusable-components/Components/SlideSwitch/SlideSwitch';
import styles from '../../ComponentSettingWrapper.module.scss';

import { mls } from 'lib/multilanguagesupport';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
interface selectedColumnPopupProps {
  isSelectedColumnPopup: boolean;
  handleIsSelectedColumnPopup: Function;
  relatedTable: any;
  relatedTableList: any;
  tableID: string;
  relationType: string;
  tableData: any;
  updateRelatedTable: Function;
}
const SelectedColumnPopup = ({
  isSelectedColumnPopup,
  handleIsSelectedColumnPopup,
  relatedTable,
  relatedTableList,
  tableID,
  relationType,
  tableData,
  updateRelatedTable,
}: selectedColumnPopupProps) => {
  return (
    <div className={styles.tablesSelectionOuterContainer}>
      <PopUpDivChild
        initialValue={isSelectedColumnPopup}
        setPopUp={handleIsSelectedColumnPopup}
        // popupTitle={mls('Select Columns')}
        popupTitle={`${tableData?.name ?? tableID} (${relationType})`}
      >
        <div className={styles.tablesSelectionContainer}>
          {React.Children.toArray(
            (tableData?.columnSchema ?? []).map((columnData: any) => {
              const isStartWithD3 = (columnData?.columnName ?? '').startsWith('__d3__');
              if (isStartWithD3) {
                return null;
              }

              const isSelected = (relatedTable?.defaultView ?? []).some(
                (columnNameElem: any) => columnNameElem?.columnName === columnData?.columnName ?? ''
              );

              const handleSelection = () => {
                let newDefaultView = relatedTable?.defaultView ?? [];
                if (isSelected) {
                  newDefaultView = (relatedTable?.defaultView ?? []).filter(
                    (columnNameElem: any) =>
                      columnNameElem?.columnName !== columnData?.columnName ?? ''
                  );
                } else {
                  newDefaultView = [
                    ...(relatedTable?.defaultView ?? []),
                    {
                      columnName: columnData?.columnName ?? '',
                      filter: {},
                      name: columnData?.name ?? '',
                      description: '',
                    },
                  ];
                }

                updateRelatedTable({
                  ...relatedTable,
                  defaultView: newDefaultView,
                });
              };

              return (
                <div className={`${styles.columnSelectionDiv} `} onClick={() => handleSelection()}>
                  <SlideSwitch isChecked={isSelected} setIsChecked={handleSelection} />
                  {columnData?.name ?? columnData?.columnName}
                </div>
              );
            })
          )}
        </div>
      </PopUpDivChild>
    </div>
  );
};

export default SelectedColumnPopup;

import React, { useRef, useState } from 'react';
import { KTSVG } from '_metronic/helpers';
import { Modal, Box } from '@mui/material';
import { mls } from 'lib/multilanguagesupport';
import { useParams } from 'react-router-dom';
import { DeleteTable } from '../../modal/add-database/DeleteTable';
import { useLocation } from 'react-router-dom';
import ConfirmDeleteModal from '../ConfirmDeleteModal';
import useUpdateTableSchema from '../../hooks/useUpdateTableSchema';
import { publishToProduction } from '../util/publishToProduction';
import { _supistaApiDelete, _supistaApiPost } from 'lib/server-connection/connections';
import store from 'lib/reusable-components/reusableUtils/redux/store';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import styles from './editTableSettingsPopup.module.scss';
import { toast } from 'react-toastify';

import EditTableSetting from './EditTableSetting';
import { finalTableUpdate } from '../util/utilFunctions';
// Actually the modal
export const EditTableSettingsPopup = ({
  allDataTableData,
  show,
  columnUpdates,
  handleClose,
  setDataTableData,
  originalDataTableSchema,
}) => {
  const [relatedTableList, setRelatedTableList] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [dataTableSchema, setDataTableSchema] = useState(originalDataTableSchema);
  const [columnNameError, setColumnNameError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [tableUpdates, setTableUpdates] = useState({
    addColumns: [],
    updateColumns: [],
    deleteColumns: [],
    renameColumns: {},
    dropTable: false,
    createTable: false,
  });

  const updateTableSchema = useUpdateTableSchema();

  const deleteTarget = useRef();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    pb: 8,
    pt: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // justifyContent: 'center',     // Causing scroll issue
    maxHeight: '80vh',
    overflow: 'auto',
    borderRadius: '.5rem',
  };
  const params = useParams();

  const handleSave = () => {
    const currentTimestamp = Date.now();
    if ((dataTableSchema.columnSchema ?? []).length <= 0) {
      toast.warn(mls('Please add a column!'));
      return;
    }

    // Collect and trim all column names
    const columnNames = dataTableSchema.columnSchema.map((col) => col.name.trim());

    // Check if all column names are non-empty and unique
    const hasEmptyNames = columnNames.includes('');
    const uniqueColumnNames = new Set(columnNames);
    const hasUniqueNames = uniqueColumnNames.size === columnNames.length;

    if (hasEmptyNames) {
      setErrorMessage('Column names cannot be empty.');
    } else if (!hasUniqueNames) {
      setErrorMessage('Column names must be unique.');
    }

    if (hasEmptyNames || !hasUniqueNames) {
      setColumnNameError(true);
      return;
    }

    setColumnNameError(false);

    setDataTableData(dataTableSchema);

    const udpatedTableUpdate = finalTableUpdate({ tableUpdates, originalDataTableSchema });

    const sendToBackend = {
      ...dataTableSchema,
      updates: udpatedTableUpdate,
      updatedAt: currentTimestamp,
    };
    _supistaApiPost(`settings/${params.appID}/DataTable`, {
      data: sendToBackend,
    })
      .then(() => {
        store.dispatch(
          create({
            setPath: reduxConstants.config.APP_STATUS.TOAST,
            value: {
              message: 'Saved successfully',
              time: 5,
              isActive: true,
            },
          })
        );
        Object.values(relatedTableList || {}).forEach((relatedTable) => {
          const sendRelatedData = {
            ...relatedTable,
            // updates: columnUpdates,
            updatedAt: currentTimestamp,
          };
          _supistaApiPost(`settings/${params.appID}/DataTable`, {
            data: sendRelatedData,
          });
          updateTableSchema(
            false,
            null,
            sendRelatedData,
            sendRelatedData.tableID,
            sendRelatedData.name
          );
        });
        publishToProduction(params.appID);
        updateTableSchema(false, null, sendToBackend, sendToBackend.tableID, dataTableSchema.name);
        handleClose();
      })
      .catch(() => {
        store.dispatch(
          create({
            setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
            value: {
              message: 'Unable to save',
              time: 5,
              isActive: true,
            },
          })
        );
      });
  };

  const location = useLocation();
  const appID = location.pathname.split('/')[1];

  const handleDeleteTable = () => {
    _supistaApiDelete(`settings/${appID}/DataTable`, {
      data: {
        tableID: dataTableSchema.tableID,
        name: dataTableSchema.name,
      },
    })
      .then((response) => {
        setDeleteOpen(false);
        handleClose();
        store.dispatch(
          create({
            setPath: reduxConstants.config.APP_STATUS.TOAST,
            value: {
              message: 'Table Deleted Successfully',
              time: 5,
              isActive: true,
            },
          })
        );
        publishToProduction(appID);
        updateTableSchema(true, dataTableSchema.tableID);
      })
      .catch((err) => {
        console.error(err);
        store.dispatch(
          create({
            setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
            value: {
              message: 'Failed To Delete Table',
              time: 5,
              isActive: true,
            },
          })
        );
      });
  };

  const closeModal = () => {
    handleClose();
  };
  return (
    <>
      <Modal anchor='right' open={show} onClose={closeModal}>
        <>
          <ConfirmDeleteModal
            isDeleteOpen={deleteOpen}
            setIsDeleteOpen={setDeleteOpen}
            handleDeleteTable={handleDeleteTable}
          />
          <DeleteTable
            setDeleteOpen={setDeleteOpen}
            deleteOpen={deleteOpen}
            handleDeleteTable={handleDeleteTable}
            tableID={dataTableSchema.tableID}
          />
          <Box sx={style}>
            <div className={`card shadow-none w-100`}>
              <div
                className='card-header stickyH'
                id='kt_explore_header bg-white d-flex justify-content-between'
                style={{
                  position: 'sticky',
                  top: 0,
                  paddingTop: 10,
                  zIndex: 200,
                  width: '100%',
                  background: 'white',
                }}
              >
                <h5 className='card-title fw-bolder fs-2' style={{ marginLeft: -20 }}>
                  {mls('Edit Data Table')}
                </h5>

                <div className='card-toolbar'>
                  <button
                    className='btn btn-sm  btn-primary mx-2'
                    disabled={columnNameError}
                    onClick={handleSave}
                  >
                    {mls('Save')}
                  </button>
                  <button
                    ref={deleteTarget}
                    onClick={() => setDeleteOpen(true)}
                    className='btn btn-sm  btn-primary btn-light-danger mx-2'
                  >
                    {mls('Delete Table')}
                  </button>
                  <button
                    type='button'
                    className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                    onClick={closeModal}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
                  </button>
                </div>
              </div>
              <EditTableSetting
                allDataTableData={allDataTableData}
                errorMessage={errorMessage}
                relatedTableList={relatedTableList}
                setRelatedTableList={setRelatedTableList}
                tableUpdates={tableUpdates}
                setTableUpdates={setTableUpdates}
                dataTableSchema={dataTableSchema}
                setDataTableSchema={setDataTableSchema}
              />
            </div>
          </Box>
        </>
      </Modal>
    </>
  );
};

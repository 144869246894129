import clsx from 'clsx';
import React, { useState } from 'react';
import { mls } from 'lib/multilanguagesupport';
import { convertToTitleCase, updateDataByIndex } from '../../util/utilFunctions';
import { defaultColumnSchema, erpAllDataType, erpNormalDataType } from '../../data/erpData';
import SelectDataTypeCase from './SelectDataTypeCase';
import UserSelection from './UserSelection';
import { toast } from 'react-toastify';
import ValidationSection from './ValidationSection';

interface columnSettingsProps {
  children: any;
  dataTableSchema: any;
  columnData: any;
  setColumnData: Function;
  tableUpdates: any;
  setTableUpdates: Function;
  setCurrentExpandedAccordion?: Function;
}

const ColumnSettings = ({
  children,
  dataTableSchema,
  columnData,
  setColumnData,
  tableUpdates,
  setTableUpdates,
  setCurrentExpandedAccordion = () => {},
}: columnSettingsProps) => {
  const [tempColumnName, setTempColumnName] = useState(columnData.columnName);

  const handleUpdateColumnData = (columnData: any) => {
    setColumnData(columnData);
  };

  const handleTableUpdate = (tableUpdateData: any) => {
    setTableUpdates(tableUpdateData);
  };
  const handleTableUpdateColumnName = ({
    columnData,
    columnName,
    isColumnName,
  }: {
    columnData: { [key: string]: any };
    columnName: string;
    isColumnName?: boolean;
  }) => {
    let addColumns = tableUpdates?.addColumns ?? [];
    let renameColumns = tableUpdates?.renameColumns ?? {};
    const addColumnsIndex = addColumns.findIndex((elem: any) => elem?.columnName === columnName);
    if (addColumnsIndex >= 0) {
      addColumns = updateDataByIndex({
        index: addColumnsIndex,
        arrayData: addColumns,
        newData: columnData,
      });
      handleTableUpdate({
        ...tableUpdates,
        addColumns: addColumns,
      });
      return;
    }
    let updateColumns = tableUpdates?.updateColumns ?? [];
    const updateColumnsIndex = updateColumns.findIndex(
      (elem: any) => elem?.columnName === columnName
    );
    if (updateColumnsIndex >= 0) {
      updateColumns = updateDataByIndex({
        index: updateColumnsIndex,
        arrayData: updateColumns,
        newData: columnData,
      });
    } else {
      updateColumns = [...updateColumns, columnData];
    }
    if (isColumnName) {
      const allReadyPresentKey = Object.keys(renameColumns).find(
        (elem: any) => renameColumns[elem] === columnName
      );
      if (allReadyPresentKey) {
        renameColumns = { ...renameColumns, [allReadyPresentKey]: columnData?.columnName };
      } else {
        renameColumns = { ...renameColumns, [columnName]: columnData?.columnName };
      }
    }
    setCurrentExpandedAccordion(tempColumnName);
    handleTableUpdate({
      ...tableUpdates,
      updateColumns: updateColumns,
      renameColumns: renameColumns,
    });
  };
  const updateColumnData = ({ key, value }: { key: string; value: any }) => {
    handleTableUpdateColumnName({
      columnData: { ...columnData, [key]: value },
      columnName: columnData?.columnName,
    });
    handleUpdateColumnData({ ...columnData, [key]: value });
  };
  const handleChangeColumnName = () => {
    const newColumnString = tempColumnName.trim();
    const isColumnNameExist = (dataTableSchema?.columnSchema ?? []).some((colElem: any) => {
      if (newColumnString === columnData.columnName) {
        return false;
      } else {
        if (newColumnString === colElem?.columnName) {
          return true;
        }
      }
      return false;
    });

    if (isColumnNameExist) {
      toast.error(mls('Column name already exist !'));
      setTempColumnName(columnData.columnName);
      return;
    }
    if ((newColumnString ?? '').toLowerCase() === 'id') {
      toast.error(mls('Column name Cannot be ID!'));
      setTempColumnName(columnData.columnName);
      return;
    }
    handleTableUpdateColumnName({
      columnData: {
        ...columnData,
        columnName: newColumnString,
        name: convertToTitleCase(newColumnString),
      },
      columnName: columnData?.columnName,
      isColumnName: true,
    });
    handleUpdateColumnData({
      ...columnData,
      columnName: newColumnString,
      name: convertToTitleCase(newColumnString),
    });
    setTempColumnName(newColumnString);
  };

  const handleChangeColumnLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateColumnData({ key: 'name', value: e.target.value });
  };
  const handleColTypeChange = (e: any) => {
    const newType = e.target.value;
    let column: { [key: string]: any } = {
      ...defaultColumnSchema,
      defaultValue: [],
      dataType: newType ?? defaultColumnSchema?.dataType,
      prevDataType: columnData?.dataType,
      columnName: columnData?.columnName ?? defaultColumnSchema?.columnName,
      name: columnData?.name ?? defaultColumnSchema?.name,
      readOnly: columnData?.readOnly ?? defaultColumnSchema?.readOnly,
      validation: columnData?.validation ?? defaultColumnSchema?.validation,
    };
    handleTableUpdateColumnName({
      columnData: column,
      columnName: column?.columnName,
    });
    handleUpdateColumnData(column);
  };
  const handleChangeDefaultValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateColumnData({ key: 'defaultValue', value: [e.target.value] });
  };
  // const checkIfColumnNameExists = (newName, indx) => {
  //   for (var i = 0; i < dataTableSchema?.columnSchema?.length; i++) {
  //     if (dataTableSchema.columnSchema[i].name === newName && i !== indx) {
  //       setColumnNameExist(true);
  //       setColumnNameError(true);
  //       break;
  //     } else {
  //       setColumnNameExist(false);
  //       setColumnNameError(false);
  //     }
  //   }
  // };

  // const checkColumnNameValid = (newName) => {
  //   const containsCharacterWhichIsNotAllowed = charactersNotAllowedInTableAndColumnName.some(
  //     (char) => {
  //       return newName.includes(char);
  //     }
  //   );
  //   containsCharacterWhichIsNotAllowed &&
  //     setColumnNameError(
  //       "Column name can only contain these special characters: space (' '), hyphen (-) and underscore (_)"
  //     );
  // };

  const handleReadOnlyChange = () => {
    updateColumnData({ key: 'readOnly', value: columnData?.readOnly ? false : true });
  };

  if (columnData.dataType === erpAllDataType.belongsTo.key) {
    return <></>;
  }
  return (
    <div id={`edit_card_`} data-bs-parent='#kt_accordion_1'>
      <div>
        <form className='fv-row mb-10'>
          <div className='row px-4 pb-4'>
            <div className='col-6'>
              <label className='form-label fs-6 fw-bolder text-dark'>{mls('Column name')}</label>
              <input
                placeholder='Column name'
                value={tempColumnName}
                onChange={(e) => setTempColumnName(e.target.value)}
                onBlur={() => {
                  handleChangeColumnName();
                }}
                className={clsx('form-control form-control-lg form-control-solid')}
                type='text'
                name='columnName'
                autoComplete='off'
                // style={{ backgroundColor: '#e4e6ef' }}
              />
            </div>
            <div className='col-6'>
              <label className='form-label fs-6 fw-bolder text-dark'>{mls('Display name')}</label>
              <input
                placeholder='Display name'
                value={columnData.name}
                onChange={(e) => handleChangeColumnLabel(e)}
                className={clsx('form-control form-control-lg form-control-solid')}
                type='text'
                name='name'
                autoComplete='off'
                // style={{ backgroundColor: '#e4e6ef' }}
              />
            </div>
          </div>
          <div className='row px-4'>
            <div className=' col-6'>
              <label className='form-label fs-6 fw-bolder text-dark'>{mls('Column Type')}</label>
              <select
                name='dataType'
                onChange={handleColTypeChange}
                className='form-select form-control form-control-lg form-control-solid'
                value={columnData.dataType || 'text'}
              >
                {React.Children.toArray(
                  erpNormalDataType.map((dataTypeElem) => {
                    return <option value={dataTypeElem.key}>{mls(dataTypeElem.name)}</option>;
                  })
                )}
              </select>
            </div>
            {columnData.dataType === erpAllDataType.text.key ? (
              <>
                <div className='col-6'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {mls('Default Value')}
                  </label>
                  <input
                    placeholder='Default Value'
                    value={columnData?.defaultValue?.[0] ?? ''}
                    onChange={(e) => handleChangeDefaultValue(e)}
                    className={clsx('form-control form-control-lg form-control-solid')}
                    type='text'
                    name='defaultValue'
                    autoComplete='off'
                    // style={{ backgroundColor: '#e4e6ef' }}
                  />
                </div>
              </>
            ) : null}
            {columnData.dataType === erpAllDataType.number.key ||
            columnData.dataType === erpAllDataType.decimal.key ? (
              <>
                <div className='col-6'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {mls('Default Value')}
                  </label>
                  <input
                    placeholder='Default Value'
                    value={columnData?.defaultValue?.[0] ?? ''}
                    onChange={(e) => handleChangeDefaultValue(e)}
                    className={clsx('form-control form-control-lg form-control-solid')}
                    type='number'
                    name='defaultValue'
                    autoComplete='off'
                    // style={{ backgroundColor: '#e4e6ef' }}
                  />
                </div>
              </>
            ) : null}
          </div>

          {columnData.dataType === erpAllDataType.select.key ? (
            <SelectDataTypeCase columnData={columnData} updateColumnData={updateColumnData} />
          ) : null}
          {columnData.dataType === erpAllDataType.user.key ||
          columnData.dataType === erpAllDataType.multipleUser.key ? (
            <UserSelection
              columnData={columnData}
              setDataTableSchema={() => {}}
              dataTableSchema={dataTableSchema}
              updateColumnData={updateColumnData}
            />
          ) : null}
          <ValidationSection
            columnSchema={columnData}
            handleUpdateColumnData={handleUpdateColumnData}
            handleTableUpdateColumnName={handleTableUpdateColumnName}
          />
          <div className='d-flex justify-content-between'>
            <label className='mt-5 ms-10 form-check form-switch form-check-custom form-check-solid'>
              <span className='form-label fs-6 fw-bolder text-dark mx-3 '>{mls('Read-Only')}</span>
              <input
                onChange={() => handleReadOnlyChange()}
                className='form-check-input'
                type='checkbox'
                checked={columnData?.readOnly}
              />
            </label>
          </div>
          <div className='d-flex justify-content-between'>
            <div></div>
            {mls(children)}
          </div>
        </form>
      </div>
    </div>
  );
};
export default ColumnSettings;

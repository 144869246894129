export const defaultDataType = {
  ALL: 'ALL',
  NUM: 'NUM',
  CAT: 'CAT',
  DATETIME: 'DATETIME',
  GEO: 'GEO',
  ID: 'ID',
  ID_TEXT: 'ID_TEXT',
  ID_NUM: 'ID_NUM',
  text: 'text',
  number: 'number',
  datePicker: 'datePicker',
  boolean: 'boolean',
  select: 'select',
  date: 'date',
  time: 'time',
  user: 'user',
  multipleUsers: 'multipleUsers',
  dateTime: 'dateTime',
  belongsTo: 'belongsTo',
};
export const allConditionData = {
  contains: {
    name: 'Contains',
    key: 'contains',
    defaultValue: '',
    valueTypeArray: false,
  },
  notContains: {
    name: 'Not Contains',
    key: 'notContains',
    defaultValue: '',
    valueTypeArray: false,
  },
  equals: {
    name: 'Equals to',
    key: 'equals',
    defaultValue: '',
    valueTypeArray: false,
  },
  notEquals: {
    name: 'Not Equals to',
    key: 'notEquals',
    defaultValue: '',
    valueTypeArray: false,
  },
  startWith: {
    name: 'Start With',
    key: 'startWith',
    defaultValue: '',
    valueTypeArray: false,
  },

  endWith: {
    name: 'End With',
    key: 'endWith',
    defaultValue: '',
    valueTypeArray: false,
  },
  include: {
    name: 'Include',
    key: 'include',
    defaultValue: [],
    valueTypeArray: true,
  },
  notIncludes: {
    name: 'Not Includes',
    key: 'notIncludes',
    defaultValue: [],
    valueTypeArray: true,
  },
  regexp: {
    name: 'Regex Expression',
    key: 'regexp',
    defaultValue: '',
    valueTypeArray: false,
  },
  lessThan: {
    name: 'Less Than',
    key: 'lessThan',
    defaultValue: '',
    valueTypeArray: false,
  },
  lessThanOrEqual: {
    name: 'Less Than Or Equal',
    key: 'lessThanOrEqual',
    defaultValue: '',
    valueTypeArray: false,
  },
  greaterThan: {
    name: 'Greater Than',
    key: 'greaterThan',
    defaultValue: '',
    valueTypeArray: false,
  },
  greaterThanOrEqual: {
    name: 'Greater Than Or Equal',
    key: 'greaterThanOrEqual',
    defaultValue: '',
    valueTypeArray: false,
  },
  inRange: {
    name: 'In Range',
    key: 'inRange',
    defaultValue: '',
    valueTypeArray: false,
  },
};
export const textConditionData = {
  contains: allConditionData.contains,
  notContains: allConditionData.notContains,
  equals: allConditionData.equals,
  notEquals: allConditionData.notEquals,
  startWith: allConditionData.startWith,
  endWith: allConditionData.endWith,
  include: allConditionData.include,
  notIncludes: allConditionData.notIncludes,
  regexp: allConditionData.regexp,
};

export const numberConditionData = {
  inRange: allConditionData.inRange,
  equals: allConditionData.equals,
  notEquals: allConditionData.notEquals,
  lessThan: allConditionData.lessThan,
  lessThanOrEqual: allConditionData.lessThanOrEqual,
  greaterThan: allConditionData.greaterThan,
  greaterThanOrEqual: allConditionData.greaterThanOrEqual,
};
export const dateConditionData = {
  equals: allConditionData.equals,
  notEquals: allConditionData.notEquals,
};
export const fiterIdConditon = {
  manager: {
    name: 'Manager Access',
    subName: "Manager's Manager Access level",
    key: 'manager',
  },
  coach: {
    name: 'Coach Access',
    subName: "Coach's Coach Access level",
    key: 'coach',
  },
  subordinate: {
    name: 'Subordinate Access',
    subName: "Subordinate's Subordinate Access level",
    key: 'subordinate',
  },
  accessTo: {
    name: 'Access To User',
    subName: 'Access To User Info',
    key: 'accessTo',
  },
};
export const fiterIdConditonArray = [
  fiterIdConditon.manager,
  fiterIdConditon.coach,
  fiterIdConditon.subordinate,
  // fiterIdConditon.accessTo,
];
export const defaultFilterIDData = {
  [fiterIdConditon.manager.key]: {
    key: fiterIdConditon.manager.key,
    value: [1],
  },
};
export const defaultDataTypeStyles = {
  [defaultDataType.ALL]: {
    key: defaultDataType.ALL,
    name: 'All',
    icon: '#123',
    color: 'green',
  },
  [defaultDataType.number]: {
    key: defaultDataType.number,
    name: 'Numerical',
    icon: '#123',
    color: 'green',
  },
  [defaultDataType.text]: {
    key: defaultDataType.text,
    name: 'Categorical',
    icon: 'Abc',
    color: 'black',
  },
  [defaultDataType.datePicker]: {
    key: defaultDataType.datePicker,
    name: 'Date Time',
    icon: 'DATE',
    color: 'orange',
  },
  [defaultDataType.boolean]: {
    key: defaultDataType.boolean,
    name: 'Boolean',
    icon: 'DATE',
    color: 'orange',
  },
  // [defaultDataType.NUM]: {
  //   key: defaultDataType.NUM,
  //   name: 'Numerical',
  //   icon: '#123',
  //   color: 'green',
  // },
  // [defaultDataType.CAT]: {
  //   key: defaultDataType.CAT,
  //   name: 'Categorical',
  //   icon: 'Abc',
  //   color: 'black',
  // },
  // [defaultDataType.DATETIME]: {
  //   key: defaultDataType.DATETIME,
  //   name: 'Date Time',
  //   icon: 'DATE',
  //   color: 'orange',
  // },

  // [defaultDataType.GEO]: {
  //   key: defaultDataType.GEO,
  //   name: 'Geographical',
  //   icon: 'GEO',
  //   color: 'black',
  // },
  [defaultDataType.ID]: {
    key: defaultDataType.ID,
    name: 'Identifier',
    icon: 'ID',
    color: 'black',
  },
  // [defaultDataType.ID_TEXT]: {
  //   key: defaultDataType.ID_TEXT,
  //   name: 'Text Identifier',
  //   icon: 'ID',
  //   color: 'black',
  // },
  // [defaultDataType.ID_NUM]: {
  //   key: defaultDataType.ID_NUM,
  //   name: 'Num Identifier',
  //   icon: 'ID',
  //   color: 'black',
  // },
};
export const defaultDateKey = {
  CUSTOM: 'CUSTOM',
  LAST7DAYS: 'LAST7DAYS',
  LASTXDAYS: 'LASTXDAYS',
  defalutLastDay: 30,
  mainKey: 'period',
};
export const defaultFilterByType = [
  defaultDataType.ALL,
  defaultDataType.CAT,
  defaultDataType.NUM,
  defaultDataType.DATETIME,
  // defaultDataType.date,
  // defaultDataType.time,
  // defaultDataType.dateTime,
  defaultDataType.GEO,
  defaultDataType.ID,
];
export const defaultDateObject = {
  [`TODAY`]: 'Today',
  [`YESTERDAY`]: 'Yesterday',
  [`THISWEEK`]: 'This week',
  [`LAST7DAYS`]: 'Last 7 days',
  [`THISMONTH`]: 'This month',
  [`LAST30DAYS`]: 'Last 30 days',
  [`THISQUARTER`]: 'This quarter',
  [`THISYEAR`]: 'This year',
  [`CUSTOM`]: 'Custom Date',
  [`LASTXDAYS`]: 'Last X day',
};
export const defaultDateArray = [
  {
    name: 'Today',
    [defaultDateKey.mainKey]: 'TODAY',
  },
  {
    name: 'Yesterday',
    [defaultDateKey.mainKey]: 'YESTERDAY',
  },
  {
    name: 'This week',
    [defaultDateKey.mainKey]: 'THISWEEK',
  },
  {
    name: 'Last 7 days',
    [defaultDateKey.mainKey]: 'LAST7DAYS',
  },
  {
    name: 'This month',
    [defaultDateKey.mainKey]: 'THISMONTH',
  },
  {
    name: 'Last 30 days',
    [defaultDateKey.mainKey]: 'LAST30Days',
  },
  {
    name: 'This quarter',
    [defaultDateKey.mainKey]: 'THISQUARTER',
  },
  {
    name: 'Last X day',
    [defaultDateKey.mainKey]: defaultDateKey.LASTXDAYS,
  },
  {
    name: 'This year',
    [defaultDateKey.mainKey]: 'THISYEAR',
  },
  {
    name: 'Custom Date',
    [defaultDateKey.mainKey]: defaultDateKey.CUSTOM,
  },
];

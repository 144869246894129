import React, { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import styles from './codeCustomization.module.scss';
import { mls } from 'lib/multilanguagesupport';
import AppComponentFilter from '../../AppComponentFilter/AppComponentFilter';
import AddTeamsPopup from '../../AddTeamsPopup/AddTeamsPopup';
import { getAppComponentColumnSchema } from '../PDFTemplates/helperFuntion';

const QueryContainerPermissionContainer = ({
  componentSchema,
  title,
  setTitle,
  activeButtonKey,
  errors,
  setErrors,
  selectedCode,
  udpateCodeCustomization,
  selectedTable,
  connectedTablesData,
}: {
  componentSchema: any;
  title: string;
  setTitle: Function;
  activeButtonKey: string;
  setComponentSchema: Function;
  errors: any;
  connectedTablesData: any;
  setErrors: Function;
  selectedCode: { [key: string]: any };
  selectedTable: string;
  udpateCodeCustomization: Function;
}) => {
  const columnSchema = useMemo(() => {
    return getAppComponentColumnSchema({
      connectedTableList: componentSchema?.connectedTableList ?? [],
      connectedTablesData,
    });
  }, [componentSchema?.connectedTableList, connectedTablesData]);

  const validateFields = () => {
    const errors: { [key: string]: any } = {};
    if (!title.trim()) {
      errors.title = 'Label is required.';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleLabelChange = (e: any) => {
    setTitle(e.target.value);
  };
  const [isDisplayWhenPopup, setIsDisplayWhenPopup] = useState(false);
  const [isDisplayToPopup, setIsDisplayToPopup] = useState(false);
  const handleFilterPopup = () => {
    setIsDisplayWhenPopup(!isDisplayWhenPopup);
  };
  const handleDisplayToPopup = () => {
    setIsDisplayToPopup(!isDisplayToPopup);
  };
  const udpateActionButtonData = ({
    activeButtonKey,
    activeButtonData,
  }: {
    activeButtonKey: string;
    activeButtonData: { [key: string]: any };
  }) => {
    udpateCodeCustomization({
      selectedTable,
      codeCustomizationData: {
        ...selectedCode,
        actionButton: {
          ...(selectedCode?.actionButton ?? {}),
          [activeButtonKey]: activeButtonData,
        },
      },
    });
  };
  const handleSaveActionButtonTitle = () => {
    if (validateFields()) {
      udpateActionButtonData({
        activeButtonKey,
        activeButtonData: {
          ...(actionButton?.[activeButtonKey] ?? {}),
          title,
        },
      });
    }
  };
  const actionButton = componentSchema?.codeCustomization?.[selectedTable]?.actionButton ?? {};
  const handleDisplayWhen = (displayWhen: any) => {
    udpateActionButtonData({
      activeButtonKey,
      activeButtonData: {
        ...(actionButton?.[activeButtonKey] ?? {}),
        displayWhen,
      },
    });
  };
  const handleDisplayTo = (displayTo: any) => {
    udpateActionButtonData({
      activeButtonKey,
      activeButtonData: {
        ...(actionButton?.[activeButtonKey] ?? {}),
        displayTo,
      },
    });
  };
  return (
    <>
      <div className={styles.queryContainerPermissionOuterContainer}>
        {isDisplayToPopup ? (
          <AddTeamsPopup
            isDisplayToPopup={isDisplayToPopup}
            setIsDisplayToPopup={setIsDisplayToPopup}
            setSelectedTeams={handleDisplayTo}
            selectedTeams={actionButton?.[activeButtonKey]?.displayTo ?? []}
          />
        ) : null}
        {isDisplayWhenPopup ? (
          <AppComponentFilter
            setChartFilter={handleDisplayWhen}
            allTableData={columnSchema}
            chartFilter={actionButton?.[activeButtonKey]?.displayWhen ?? {}}
            appID={''}
            sheetID={''}
            isJoinedTable={false}
            isPopUp={isDisplayWhenPopup}
            setIsPopUp={setIsDisplayWhenPopup}
          />
        ) : null}
        <div className={styles.queryContainerPermissionContainer}>
          <Form.Group controlId='actionButtonLabel' className={styles.actionButtonOptions}>
            <Form.Label className={styles.actionButtonOptionsLabel}>
              {mls('Action Name')}:
            </Form.Label>
            <Form.Control
              type='text'
              value={title}
              onChange={handleLabelChange}
              onBlur={() => handleSaveActionButtonTitle()}
              isInvalid={!!errors?.title}
            />
            <Form.Control.Feedback type='invalid'>{errors?.title}</Form.Control.Feedback>
          </Form.Group>
          <div>
            <b>{mls('Display When')}:</b> &nbsp;
            <button
              className='primaryButton secondaryButtonColor'
              onClick={() => handleFilterPopup()}
            >
              {mls('Filters ')}
            </button>
          </div>

          <div>
            <b>{mls('Display to')}:</b> &nbsp;
            <button
              className='primaryButton secondaryButtonColor'
              onClick={() => handleDisplayToPopup()}
            >
              {mls('Select Teams')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default QueryContainerPermissionContainer;
